import React,{Fragment} from 'react'
import { Link } from 'react-router-dom'

const ServiceContent = [
    {
        icon: "icon_32",
        title: 'Engaging conversations',
        desc: `Upload files, convert them into smart, engaging chats, and experience the most interactive reading experience you've ever had.`,
        arrow: '',
        datadelay: '',
        dataAos: 'fade-right',
        className: 'active'
    }, {
        icon: "icon_31",
        title: 'Maximise efficiency',
        desc: `Turn your documents into interesting chats with friendly AI assistants. No more tiresome scrolling or skimming, just easy understanding.`,
        arrow: 'icon_20',
        datadelay: '100',
        dataAos: 'fade-up',
        className: 'active'
    }, {
        icon: "icon_33",
        title: 'Fun learning',
        desc: `Our smart AI doesn't just give you the facts, it makes learning a joy. Dive into a world of playful discovery with Talk to PDF!`,
        arrow: 'icon_20',
        dataDelay: '',
        dataAos: 'fade-left',
        className: 'active'
        
    }
]
const FancyFeatureSeventeen = () => {
    return ( 
    <Fragment> 
      <div className="row justify-content-center pt-30">
          {ServiceContent.map((val, i) => (
              <div key={i} className="col-lg-4 col-md-6" data-aos={val.dataAos} data-aos-delay={val.dataDelay}>
                  <div
                      className={`block-style-twelve block-space mt-30 ${val.className}`}>
                      <div className="icon d-flex align-items-end"><img src={`images/icon/${val.icon}.svg`} alt=""/></div>
                      <h5 className="mt-30">
                        
                          {val.title}
                      </h5>
                      <p>{val.desc}</p>
                      {/* <Link to="/service-details" className="tran3s more-btn"><img src="images/icon/icon_20.svg" alt=""/></Link> */}
                  </div>
                  {/* /.block-style-twelve */}
              </div>
          ))}

      </div> 
    </Fragment>
  )
}

export default FancyFeatureSeventeen