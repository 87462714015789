import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useJitsu } from "@jitsu/jitsu-react";
import { EVENT_NAMES } from "../eventNames";
import { trackEvent } from "../events";

const CopyRightFour = () => {
	const { analytics } = useJitsu();

	const onContactUs = () => {
		trackEvent(analytics, EVENT_NAMES.CONTACT_US_CTA);
	};

	const onPrivacyAndTerms = () => {
		trackEvent(analytics, EVENT_NAMES.PRIVACY_TERMS_CTA);
	}
	
	return (
		<Fragment>
			<div className="d-lg-flex justify-content-between align-items-center">
				<ul className="order-lg-1 pb-15 d-flex justify-content-center footer-nav style-none">
					<li>
						<Link
							onClick={onPrivacyAndTerms}
							to="https://zoopsign.com/privacy-policy/"
							target="_blank"
							rel="noopener noreferrer">
							Privacy &amp; Terms.
						</Link>
					</li>
					{/* <li>
                        <Link to="/faq">FAQ</Link>
                    </li> */}
					<li>
						<Link
							onClick={onContactUs}
							to="https://app.zoopsign.com/contact"
							target="_blank"
							rel="noopener noreferrer">
							Contact Us
						</Link>
					</li>
				</ul>
				<p className="copyright text-center order-lg-0 pb-15">
					Copyright @{new Date().getFullYear()} Zoopsign Tech Pvt Ltd
				</p>
			</div>
		</Fragment>
	);
};

export default CopyRightFour;
