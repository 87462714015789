import React, { Fragment } from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const TestimonialContent = [
	{
		img: "icon_34",
		desc: `I've been using Talk to PDF for a couple of weeks now and it's really transformed how I interact with my documents. It's almost like having a personal assistant!`,
		name: "Leela Rajput",
		desig: "Freelance Writer",
	},
	{
		img: "icon_34",
		desc: `As a PhD student, I have to go through tons of research papers. Talk to PDF has made my life so much easier. It's a very innovative tool!`,
		name: "Ajay Doshi",
		desig: "Student B.Tech",
	},
	{
		img: "icon_34",
		desc: `I was skeptical at first, but Talk to PDF really does make reading documents more interactive and engaging. It's like having a conversation with my reading materials.`,
		name: "Hafeez Hasan",
		desig: "Compliance Lead",
	},
	{
		img: "icon_34",
		desc: `Talk to PDF has been a great help in my work. It's much easier to find specific information in my documents now. Plus, it's actually fun to use!`,
		name: "Rohan Dutta",
		desig: "Sales Head",
	},
];

const settings = {
	dots: true,
	infinite: true,
	fade: true,
	speed: 500,
	autoplay: true,
	slidesToShow: 1,
	slidesToScroll: 1,
};

const TestimonialFive = () => {
	return (
		<Fragment>
			<Slider className="feedback_slider_four" {...settings} data-aos="fade-up">
				{TestimonialContent.map((val, i) => (
					<div key={i} className="item">
						<div className="row">
							<div className="col-xxl-9 col-xl-10 col-lg-8 m-auto">
								<div className="feedback-block-four mb-80 ms-xxl-4 me-xxl-4">
									<img
										src={`images/icon/${val.img}.svg`}
										alt="avatar"
										className="m-auto"
									/>
									<p>{val.desc}</p>
									<div className="cp-info">
										<h6>{val.name}</h6>
										<span>{val.desig}</span>
									</div>
								</div>
								{/* /.feedback-block-four */}
							</div>
						</div>
					</div>
				))}
			</Slider>
		</Fragment>
	);
};

export default TestimonialFive;
