import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";

import { BrowserRouter } from "react-router-dom";
import AppRouter from "./router/AppRouter";
import ScrollToTop from "./components/ScrollToTop";

function App() {
	useEffect(() => {
		AOS.init({
			duration: 1200,
		});
	}, []);

	return (
		<Fragment>
			<Helmet>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta
					name="keywords"
					content="Document, Analysis, Data, sass, software company, ML"
				/>
				<meta
					name="description"
					content="Talk to PDF - Bring your documents to life; make reading simple, engaging and fun!"
				/>
				<meta property="og:site_name" content="Talk-to-PDF" />
				<meta
					property="og:url"
					content="https://themeforest.net/user/creativegigs"
				/>
				<meta property="og:type" content="website" />
				<meta
					property="og:title"
					content="Talk to PDF - Upload files, convert them into smart, engaging chats, and experience the most interactive reading experience you've ever had."
				/>
			</Helmet>
			{/* {End Seo Helmet}
			 */}
			<ScrollToTop />
			<BrowserRouter>
				<AppRouter />
			</BrowserRouter>
		</Fragment>
	);
}

export default App;
