import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import SearchModal from "../search-modal/SearchModal";
import MobileMenu from "./MobileMenu";
import { useJitsu } from "@jitsu/jitsu-react";
import ThemeMainMenu from "./ThemeMainMenu";
import { EVENT_NAMES } from "../eventNames";
import { trackEvent } from "../events";

const TopNavFour = () => {
  const { analytics } = useJitsu();
  const [navbar, setNavbar] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(!modalIsOpen);
  };

  const toggleMenu = () => {
    if (window.scrollY >= 68) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const onTryForFree = () => {
    trackEvent(analytics, EVENT_NAMES.TRY_FOR_FREE_CTA)
  };

  const onContactUs = () => {
    trackEvent(analytics,EVENT_NAMES.CONTACT_US_CTA);
  };

  window.addEventListener("scroll", toggleMenu);

  return (
    <Fragment>
      <SearchModal
        isOpen={modalIsOpen}
        onClick={closeModal}
        bgColor="bg-three"
      />
      <header
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-four fixed"
            : "theme-main-menu sticky-menu theme-menu-four"
        }
      >
        <div className="inner-content">
          <div className="d-flex align-items-center">
            <div className="logo bg-red-500">
              <Link to="/" className="d-block">
                <svg
                  width="94"
                  height="94"
                  viewBox="0 0 122 124"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="60.75"
                    cy="60.75"
                    r="60.75"
                    transform="matrix(1 0 0 -1 0.25 121.75)"
                    fill="url(#paint0_linear_14330_6814)"
                  />
                  <circle cx="91" cy="61" r="11" fill="white" />
                  <circle cx="29" cy="61" r="11" fill="white" />
                  <circle
                    cx="60"
                    cy="61"
                    r="35.25"
                    fill="white"
                    stroke="url(#paint1_linear_14330_6814)"
                    stroke-width="1.5"
                  />
                  <path
                    d="M33.6 83.0001L43.8 90.8L31.5219 95.8037L33.6 83.0001Z"
                    fill="white"
                  />
                  <path
                    d="M56 63C56 61.8181 55.7672 60.6478 55.3149 59.5558C54.8626 58.4639 54.1997 57.4718 53.364 56.636C52.5282 55.8003 51.5361 55.1374 50.4442 54.6851C49.3522 54.2328 48.1819 54 47 54C45.8181 54 44.6478 54.2328 43.5558 54.6851C42.4639 55.1374 41.4718 55.8003 40.636 56.636C39.8003 57.4718 39.1374 58.4639 38.6851 59.5558C38.2328 60.6478 38 61.8181 38 63"
                    stroke="#0D54FF"
                    stroke-width="3"
                    stroke-linecap="round"
                  />
                  <path
                    d="M82 63C82 61.8181 81.7672 60.6478 81.3149 59.5558C80.8626 58.4639 80.1997 57.4718 79.364 56.636C78.5282 55.8003 77.5361 55.1374 76.4442 54.6851C75.3522 54.2328 74.1819 54 73 54C71.8181 54 70.6478 54.2328 69.5558 54.6851C68.4639 55.1374 67.4718 55.8003 66.636 56.636C65.8003 57.4718 65.1374 58.4639 64.6851 59.5558C64.2328 60.6478 64 61.8181 64 63"
                    stroke="#0D54FF"
                    stroke-width="3"
                    stroke-linecap="round"
                  />
                  <path
                    d="M89.222 113.417L104.638 101.308L107.359 121.365L89.222 113.417Z"
                    fill="#408BFE"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_14330_6814"
                      x1="22.75"
                      y1="12.75"
                      x2="95.75"
                      y2="108.75"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#61AFFD" />
                      <stop offset="1" stop-color="#0A51FF" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_14330_6814"
                      x1="89"
                      y1="38"
                      x2="35"
                      y2="91.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#1259FF" />
                      <stop offset="1" stop-color="#57A4FE" />
                    </linearGradient>
                  </defs>
                </svg>
              </Link>
            </div>

            <div className="right-widget d-flex align-items-center ms-auto order-lg-3">
              <div className="call-button d-none d-xl-block me-5">
                <a
                  onClick={onContactUs}
                  href="https://app.zoopsign.com/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact us
                </a>
              </div>
              <button
                className="menu-search-btn tran3s"
                type="button"
                onClick={openModal}
              >
                <i className="bi bi-search" />
              </button>

              <Link
                onClick={onTryForFree}
                to="https://app.zoopsign.com/talktopdf"
                target="_blank"
                rel="noopener noreferrer"
                className="send-msg-btn tran3s d-none d-lg-block"
              >
                Try for Free
              </Link>
            </div>
            {/* /.right-widget */}
            <nav className="navbar navbar-expand-lg order-lg-2">
              <div className="collapse navbar-collapse" id="navbarNav">
                <ThemeMainMenu />
                {/* Mobile Content */}
                <div className="mobile-content d-block d-lg-none">
                  <div className="d-flex flex-column align-items-center justify-content-center mt-70">
                    <a
                      href="contact-us.html"
                      className="send-msg-btn tran3s mb-10"
                    >
                      Send us Message
                    </a>
                    <div className="call-button">
                      Call us
                      <a href="#">(+880) 321 782 110</a>
                    </div>
                  </div>
                </div>
                {/* /.mobile-content */}
              </div>
            </nav>
          </div>

          <MobileMenu />
          {/* mobilemenu */}
        </div>
        {/* /.inner-content */}
      </header>
      {/* /.theme-main-menu */}
    </Fragment>
  );
};

export default TopNavFour;
