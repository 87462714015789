import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/main.scss';
import { JitsuProvider } from '@jitsu/jitsu-react';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <JitsuProvider  options={{
    host: "https://jitsu-dev.zoop.tools",
    writeKey:
      "CR0LxfkSUflPwsIwKWkvn8cPU9H2xrSL:lscAr1oxT9S0B4HwZUppeVrBzvlswWML",
  }}>
    <App />
  </JitsuProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// const { analytics } = useJitsu();

// const onCheckPricing: MouseEventHandler<HTMLAnchorElement> = async (
//   event
// ) => {
//   event.preventDefault();
//   await analytics.track(EVENT_NAMES.CHECK_PRICING, {
//     EventSource: "ZOOPSIGN",
//   });
//   window.location.href = (event.target as HTMLAnchorElement).href;
// };
