import React, { Fragment } from "react";
import { useJitsu } from "@jitsu/jitsu-react";
import { EVENT_NAMES } from "./../eventNames";
import { trackEvent } from "../events";

const About = () => {
  const { analytics } = useJitsu();

  const onExploreNow = () => {
    trackEvent(analytics, EVENT_NAMES.EXPLORE_NOW_CTA);
  };

  return (
    <Fragment>
      <div className="fancy-feature-eighteen position-relative pt-200 pb-225 lg-pt-130 md-pt-100 xl-pb-150 lg-pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-7 ms-auto">
              <div className="block-style-two" data-aos="fade-left">
                <div className="title-style-three">
                  <div className="sc-title">Over 10k+ Users</div>
                  <h2 className="main-title">
                    The best way to <span>Chat. Learn. Grow.</span>{" "}
                  </h2>
                </div>
                {/* /.title-style-three */}
                <p className="pt-20 pb-25 lg-pb-20">
                  Introducing a fresh, fun way to interact with your documents.
                  Turn them into:
                </p>
                <ul className="style-none list-item color-rev">
                  <li>Chat buddies</li>
                  <li>Keep tabs on your talks</li>
                  <li>Get sensitive informations with a click</li>
                </ul>
                <a
                  onClick={onExploreNow}
                  href="https://app.zoopsign.com/talktopdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-eight mt-50 lg-mt-30"
                >
                  Explore Now!
                </a>
              </div>
              {/* /.block-style-two */}
            </div>
          </div>
        </div>

        <div className="illustration-holder" data-aos="fade-right">
          <img
            src="images/assets/ils_14.svg"
            alt=""
            className="w-100 main-illustration"
          />
          <img
            src="images/assets/ils_14_1.svg"
            alt=""
            className="shapes shape-one"
            data-aos="fade-down"
          />
          <img
            src="images/assets/ils_14_2.svg"
            alt=""
            className="shapes shape-two"
            data-aos="fade-down"
            data-aos-delay={100}
          />
          <img
            src="images/assets/ils_14_3.svg"
            alt=""
            className="shapes shape-three"
            data-aos="fade-down"
            data-aos-delay={200}
          />
          <img
            src="images/assets/ils_14_4.svg"
            alt=""
            className="shapes shape-four"
          />
          <img
            src="images/assets/ils_14_5.svg"
            alt=""
            className="shapes shape-five"
          />
          <img
            src="images/assets/ils_14_6.svg"
            alt=""
            className="shapes shape-six"
          />
          <img
            src="images/assets/ils_14_7.svg"
            alt=""
            className="shapes shape-seven"
          />
        </div>
        {/* /.illustration-holder */}
        <div className="shapes oval-one" />
        <div className="shapes oval-two" />
        <div className="shapes oval-three" />
      </div>
    </Fragment>
  );
};

export default About;
