import React, {Fragment} from 'react';
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';

const FaqContent = [
    {
        title: 'How does Talk to PDF make documents interactive?',
        desc: `Talk to PDF makes your documents interactive by using our unique technology and advanced AI models that pull out information based on context from your document.`,
        expand: 'a'
    }, {
        title: 'What document types can I use with Talk to PDF?',
        desc: ` At the moment, Talk to PDF only supports PDF files, but our team is working hard to include more file types soon.`,
        expand: 'b'
    }, {
        title: 'Is there an upload size limit for Talk to PDF?',
        desc: `There's an upload limit of 100MB per file.`,
        expand: 'c'
    }, {
        title: 'How does Talk to PDF utilize AI technology?',
        desc: `Talk to PDF leverages AI technology by using pre-trained AI models to enable its features, rather than using user data to train the models.`,
        expand: 'd'
    }, {
        title: 'Is the application free to use?',
        desc: `Yes, you can use Talk to PDF for free for selective number of pages.`,
        expand: 'e'
    }, {
        title: 'Can I chat in any language?',
        desc: `Right now, Talk to PDF only supports English. However, we're working on incorporating more languages.`,
        expand: 'f'
    }
]

const Faq = () => {
    return (
        <Fragment>
            <Accordion
                className="accordion-style-one"
                preExpanded={["d"]}
                allowZeroExpanded>
                {FaqContent.map((item, i) => (
                    <AccordionItem className="accordion-item" key={i} uuid={item.expand}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <button className="accordion-button" type="button">
                                    {item.title}
                                </button>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="accordion-body fadeInUp">
                            <p>
                                {item.desc}
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Fragment>
    )
}

export default Faq